import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild, NgZone } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';

interface Message {
  text: string;
  isUser: boolean;
  isLast: boolean;
}

@Component({
  selector: 'app-chatbot-scan',
  templateUrl: './chatbot-scan.component.html',
  styleUrls: ['./chatbot-scan.component.scss']
})
export class ChatbotScanComponent implements OnInit, AfterViewChecked {

  @ViewChild('messagesContainer') private messagesContainer!: ElementRef;

  messages: Message[] = [];
  newMessage: string = '';
  sending: boolean = false;
  private isUserScrolling: boolean = false;
  private previousMessagesLength: number = 0; // Almacena la cantidad de mensajes anteriores
  public tenantId; 
  constructor(
    public apiService: ApiService,
    private ngZone: NgZone,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.tenantId = this.authService.getTenant().tenantid;
    const botMessage: Message = { text: 'Hola! en que puedo ayudarte?', isUser: false, isLast: false };
    this.messages.push((botMessage));
    this.sending = false;
    this.apiService
      .post('graph_rag/reset_memory', {}, false, false, {}, false)
      .subscribe(
        (response) => {
          console.log('Reset funcionando!')
        },
        (error) => {
          console.log(error);
        }
      );
    if (this.messagesContainer) {
      this.messagesContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    }
    this.previousMessagesLength = this.messages.length; // Inicializa el contador
  }

  ngAfterViewChecked(): void {
    if (!this.isUserScrolling && this.messages.length > this.previousMessagesLength) {
      this.scrollToBottom();
    }
    this.previousMessagesLength = this.messages.length; // Actualiza el contador
  }

  formatResponse(botMessage): string {
    let formattedMessage = botMessage.replace(/\n/g, '<br>');
    formattedMessage = formattedMessage.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    formattedMessage = formattedMessage.replace(/\*(.*?)\*/g, '<em>$1</em>');
    return formattedMessage;
  }

  send(): void {
    this.sending = true;

    if (this.newMessage.trim() !== '') {
      const userMessage: Message = { text: this.newMessage, isUser: true, isLast: true };
      this.messages.push(userMessage);

      // Guardar una referencia al último mensaje agregado
      const lastUserMessage = this.messages[this.messages.length - 1];

      this.apiService
        .post('graph_rag/ask', { "input": userMessage.text, "company_id": String(this.tenantId) }, false, false, {}, false)
        .subscribe(
          (response) => {
            lastUserMessage.isLast = false; // Actualizar el último mensaje agregado
            const botMessage: Message = { text: this.formatResponse(response.answer), isUser: false, isLast: false };
            this.messages.push((botMessage));
            this.sending = false;
          },
          (error) => {
            this.ngZone.run(() => {
              lastUserMessage.isLast = false; 
              this.sending = false;
              console.log(error);
            });
          }
        )

      this.newMessage = '';
    } else {
      this.sending = false;
    }
  }

  cleanChat(): void {
    this.messages = [];
    this.previousMessagesLength = 0;
  }

  private scrollToBottom(): void {
    try {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error('Error al hacer scroll:', err);
    }
  }

  onScroll(): void {
    const element = this.messagesContainer.nativeElement;
    this.isUserScrolling = element.scrollTop + element.clientHeight !== element.scrollHeight;
  }
}